export function isShwo(type, info, other) {
  const { payImages, billImages, reviewImages } = info.imagesInfo
  const cur = reviewImages.find(item => item.voucher_type == type)
  if (other) {
    if (reviewImages.length) {
      return reviewImages.every(item => item.audit_status === 3)
    } else {
      return false
    }
  }
  if (type === 1) {
    return payImages.length
  } else {
    return billImages.length
  }
}

/**
 *  @params 传进来的时间
 *  @return 返回一个10分钟后的时间 - 去创建的时间  
 */
export function getDwonTime(data) {
  // 处理倒计时
  const time = new Date(data.created_at).getTime()
  // 10分钟后的时间戳
  const futureDate = time + 60 * 60 * 1000
  const nowDate = new Date().getTime()
  return futureDate - nowDate
}
